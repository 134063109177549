import './css/Footer.css';



















export default function Footer() {
  return (
    <footer className='fixed bottom-0 left-0 right-0 p-1 w-full flex flex-col items-center bg-[#150924] text-[#453b66] italic'>
      Developed and hosted by Mohammad KHADDAN
    </footer>
  );
};
