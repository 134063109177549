import Header from '../components/Header';
import Footer from '../components/Footer';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import './css/Whoami.css';

import logo_thales from '../components/svg/logo-thales.svg';
import logo_mgen from '../components/img/logo-mgen.png';
import logo_esiea from '../components/svg/logo-esiea.svg';
import logo_iut from '../components/img/logo-iut.png';
import me_head from '../components/img/me.png';
import logo_linkedin from '../components/svg/logo-linkedin.svg';
import logo_github from '../components/svg/logo-github.svg';
import logo_centria from '../components/img/logo-centria.png';
import logo_mail from '../components/img/mail_img.png';


const Tags = () => {
    return (
        <div id='tags' className='hidden'>
            <p>
                Page de contact ; Mohammad KHADDAN ; IT engineer ; CV ; informations de contact ; contact info ; email ; email address ; adresse email ; phone number ; numéro de téléphone ; linkedin ; github ; twitter ; instagram ; facebook ; youtube ; twitch ; discord ; reddit ; snapchat ; tiktok ; pinterest ; whatsapp ; telegram ; signal ; skype ; zoom ; teams ; google meet ; jitsi ; meet.jit.si ; mohammadkdn ; mohammad.kdn ; mohammad-kdn ; mohammad_kdn ; mohammad-khaddan ; mohammad_kh
            </p>
        </div>
    )
}

const CONTENT_FR = () => {
    const location = useLocation();
    const hash = location.hash;

    if (hash === "#career") {
        return (
            <div className='glass p-3 mt-3 col-span-4 grid grid-cols-1 grid-flow-row gap-4'>
                <div className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3'>
                    <h5 className='flex text-lg font-bold col-span-2 items-center'>
                        THALES
                    </h5>
                    <a className='flex justify-end row-span-2 items-center ml-6' href="https://thalesgroup.com/" target='blank_'>
                        <img className='max-h-14' src={logo_thales} alt=''/>
                    </a>
                    <p className='col-span-2'>
                        <b>Developpeur Réseaux et Sécurité</b>
                    </p>
                    <p className='mt-1 text-sm text-left italic'>
                        Alternance
                    </p>
                    <p className='col-span-2 m-1 text-sm text-right italic'>
                        Sept. 2022 - Aout 2025
                    </p>
                    <hr className='mt-3 col-span-2'/>
                    <p className='col-span-3 mt-2'>
                        DevOps / DevSecOps <br/>
                        Automatisation des processus de traitement de tâches<br/>
                        Analyse et gestion des flux réseau & management de Firewalls <br/>
                        Développement d'outils d'administration (voir <a className='italic underline hover:no-underline' href='/portfolio#gambas'>Projet GAMBAS</a>)<br/>
                    </p>
                </div>
                <div className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3'>
                    <h5 className='flex text-lg font-bold col-span-2 items-center'>
                        MGEN
                    </h5>
                    <a className='flex justify-end row-span-2 items-center ml-6' href="https://mgen.fr/" target='blank_'>
                        <img className='max-w-full max-h-14' src={logo_mgen} alt=''/>
                    </a>
                    <p className='col-span-2'>
                        <b>Developpeur Python</b>
                    </p>
                    <p className='mt-1 text-sm text-left italic '>
                        Stage
                    </p>
                    <p className='col-span-2 m-1 text-sm text-right italic'>
                        Mars 2022 - Juin 2022
                    </p>
                    <hr className='mt-3 col-span-2'/>
                    <p className='col-span-3 mt-2'>
                        Développement d'un connecteur API pour routage de mail par IA (voir <a className='italic underline hover:no-underline' href='/portfolio#melusine'>Projet Melusine|CamemBert</a>)<br/>
                        Soutient aux équipes datascience R&D sur divers projets de développement et problématiques CI/CD.
                    </p>
                </div>
            </div>
            
        );
    } else if (hash === "#education") {
        return (
            <div className='glass p-3 mt-3 col-span-4 grid grid-cols-1 grid-flow-row gap-4'>
                <div className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3'>
                    <h5 className='flex text-lg font-bold col-span-2 items-center'>
                        ESIEA Paris
                    </h5>
                    <a className='flex justify-end row-span-2 items-center ml-6' href="https://www.esiea.fr/" target='blank_'>
                        <img className='max-w-full max-h-14 rounded' src={logo_esiea} alt=''/>
                    </a>
                    <p className='col-span-2'>
                        <b>Diplôme d'ingénieur</b>
                    </p>
                    <p className='mt-1 text-sm text-left italic'>
                        Bac+5 (180 Crédits ECTS)
                    </p>
                    <p className='col-span-2 m-1 text-sm text-right italic'>
                        Sept. 2022 - Aout 2025
                    </p>
                    <hr className='mt-3 col-span-2'/>
                    <p className='col-span-3 mt-2'>
                        Cursus FISA (Formation Ingénieur sous Statut Apprenti) en alternance chez Thales.<br/>
                        Majeure Cybersécurité.<br/>
                    </p>
                </div>

                <div className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3'>
                    <h5 className='flex text-lg font-bold col-span-2 items-center'>
                        Centria UAS
                    </h5>
                    <a className='flex justify-end row-span-2 items-center ml-6' href="https://net.centria.fi/en/" target='blank_'>
                        <img className='max-w-full max-h-14 rounded' src={logo_centria} alt=''/>
                    </a>
                    <p className='col-span-2'>
                        <b>Summer school</b>
                    </p>
                    <p className='mt-1 text-sm text-left italic'>
                        Validation du semestre 6 de l'ESIEA
                    </p>
                    <p className='col-span-2 m-1 text-sm text-right italic'>
                        Mai 2023 - Juil. 2023
                    </p>
                    <hr className='mt-3 col-span-2'/>
                    <p className='col-span-3 mt-2'>
                        Période de 7 semaines en Finlande.<br/>
                        Cours dispensés en anglais.<br/>
                        Soft skills de l'ingénieur<br/>
                        Développement web avec Django & React.JS<br/>
                    </p>
                </div>
                
                <div className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3'>
                    <h5 className='flex text-lg font-bold col-span-2 items-center'>
                        IUT Reims-Châlons-Charleville
                    </h5>
                    <a className='flex justify-end row-span-2 items-center ml-6' href="https://www.univ-reims.fr/iut-rcc/" target='blank_' >
                        <img className='max-w-full max-h-14' src={logo_iut} alt=''/>
                    </a>
                    <p className='col-span-2'>
                        <b>DUT Informatique</b>
                    </p>
                    <p className='mt-1 text-sm text-left italic'>
                        Bac+2 (120 Crédits ECTS)
                    </p>
                    <p className='col-span-2 m-1 text-sm text-right italic'>
                        Sept. 2020 - Aout 2022
                    </p>
                    <hr className='mt-3 col-span-2'/>
                    <p className='col-span-3 mt-2'>
                        Acquisition de compétences professionnelles en programmation et développement applicatif.
                    </p>
                </div>
            </div>
        );
    } else if (hash === "#socials") {
        return (
            <div className='glass p-3 mt-3 col-span-4 grid grid-cols-1 grid-flow-row gap-4'>
                <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3 hover:border-white' href="https://www.linkedin.com/in/mkhaddan/" target="_blank" rel="noreferrer">
                    <h5 className='flex text-lg font-bold col-span-2 items-center'>
                        Linkedin
                    </h5>
                    <div className='flex justify-end row-span-2 items-center'>
                        <img className='max-h-8 text-right mt-1 mb-1' src={logo_linkedin} alt=''/>
                    </div>
                    <div className='col-span-2'>
                        @mkhaddan
                    </div>
                </a>
                <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3 hover:border-white' href="https://github.com/MOSEMBIK" target="_blank" rel="noreferrer">
                    <h5 className='flex text-lg font-bold col-span-2 items-center'>
                        GitHub
                    </h5>
                    <div className='flex justify-end row-span-2 items-center'>
                        <img className='max-h-8 text-right mt-1 mb-1' src={logo_github} alt=''/>
                    </div>
                    <div className='col-span-2'>
                        @MOSEMBIK
                    </div>
                </a>
                <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3 hover:border-white' href="mailto://mohammad.khaddan@gmail.com" target="_blank" rel="noreferrer">
                    <h5 className='flex text-lg font-bold col-span-2 items-center'>
                        Mail
                    </h5>
                    <div className='flex justify-end row-span-2 items-center'>
                        <img className='max-h-8 text-right mt-1 mb-1' src={logo_mail} alt=''/>
                    </div>
                    <div className='col-span-2'>
                        mohammad.khaddan@gmail.com
                    </div>
                </a>
            </div>
        );
    } else {
        return (
            <div className='glass p-3 mt-3 col-span-4 grid grid-cols-1 grid-flow-row gap-4'>
                <div className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3'>
                    <h5 className='flex items-center text-lg font-bold col-span-2'>Mohammad KHADDAN</h5>
                    <div className='flex row-span-3 justify-end items-center'>
                        <img className='max-w-full max-h-20' src={me_head} alt=''/>
                    </div>
                    <b className='flex items-center text-base font-bold col-span-2'>DevOps, DevNetOps & Passionné d'IT</b>
                    <hr className='mt-3 col-span-2'/>
                    <p className='col-span-3 mt-2'>
                        Passionné de technologie, d'innovation et de résolution de problèmes.<br/>
                        Ayant toujours aimer apprendre, je suis sans cesse motivé à améliorer mes compétences et acquérir de nouvelles connaissances.<br/>
                    </p>
                    <hr className='mt-3 col-span-3 border-dashed'/>
                    <p className='col-span-3 mt-2'>
                        Spécialisé sur Python et les languages de scripting et rapide pour assimiler de nouveaux contextes, je suis capable de fluidifier et automatiser vos tâches et workflows.<br/>
                        Avec de l'experience sur les API et les microservices, je suis capable de vous aider à concevoir et déployer des applications modernes et scalables OnPremise ou en Cloud.<br/>
                    </p>
                    <hr className='mt-3 col-span-3 border-dashed'/>
                    <p className='col-span-3 mt-2'>
                        Vous pouvez retouvez ici mes réseaux si vous souhaitez me contacter.<br/>
                        {'-> '} 
                        <a className="italic underline hover:no-underline hover:font-medium" href="#socials">
                            Mes réseaux.
                        </a>
                    </p>
                </div>
            </div>
        );
    };
}

const CONTENT_EN = () => {
    const location = useLocation();
    const hash = location.hash;

    if (hash === "#career") {
        return (
            <div className='glass p-3 mt-3 col-span-4 grid grid-cols-1 grid-flow-row gap-4'>
                <div className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3'>
                    <h5 className='flex text-lg font-bold col-span-2 items-center'>
                        THALES
                    </h5>
                    <a className='flex justify-end row-span-2 items-center ml-6' href="https://thalesgroup.com/" target='blank_'>
                        <img className='max-h-14' src={logo_thales} alt=''/>
                    </a>
                    <p className='col-span-2'>
                        <b>Network and Security Developer</b>
                    </p>
                    <p className='mt-1 text-sm text-left italic'>
                        Apprenticeship
                    </p>
                    <p className='col-span-2 m-1 text-sm text-right italic'>
                        Sept. 2022 - Aug. 2025
                    </p>
                    <hr className='mt-3 col-span-2'/>
                    <p className='col-span-3 mt-2'>
                        DevOps / DevSecOps <br/>
                        Tasks and workflows automation <br/>
                        Network flows analysis & Firewalls management  <br/>
                        Administration tools development (see <a className='italic underline hover:no-underline' href='/portfolio?lang=en#gambas'>Project GAMBAS</a>)<br/>
                    </p>
                </div>
                <div className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3'>
                    <h5 className='flex text-lg font-bold col-span-2 items-center'>
                        MGEN
                    </h5>
                    <a className='flex justify-end row-span-2 items-center ml-6' href="https://mgen.fr/" target='blank_'>
                        <img className='max-w-full max-h-14' src={logo_mgen} alt=''/>
                    </a>
                    <p className='col-span-2'>
                        <b>Python Developer</b>
                    </p>
                    <p className='mt-1 text-sm text-left italic '>
                        Internship
                    </p>
                    <p className='col-span-2 m-1 text-sm text-right italic'>
                        Mar. 2022 - June 2022
                    </p>
                    <hr className='mt-3 col-span-2'/>
                    <p className='col-span-3 mt-2'>
                        Development of an API connector for AI email routing (see <a className='italic underline hover:no-underline' href='/portfolio?lang=en#melusine'>Project Melusine|CamemBert</a>)<br/>
                        Support for data science R&D teams on various development projects and CI/CD issues.
                    </p>
                </div>
            </div>
            
        );
    } else if (hash === "#education") {
        return (
            <div className='glass p-3 mt-3 col-span-4 grid grid-cols-1 grid-flow-row gap-4'>
                <div className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3'>
                    <h5 className='flex text-lg font-bold col-span-2 items-center'>
                        ESIEA Paris
                    </h5>
                    <a className='flex justify-end row-span-2 items-center ml-6' href="https://www.esiea.fr/" target='blank_'>
                        <img className='max-w-full max-h-14 rounded' src={logo_esiea} alt=''/>
                    </a>
                    <p className='col-span-2'>
                        <b>Engineering degree</b>
                    </p>
                    <p className='mt-1 text-sm text-left italic'>
                        Bac+5 (180 ECTS Credits)
                    </p>
                    <p className='col-span-2 m-1 text-sm text-right italic'>
                        Sept. 2022 - Aug. 2025
                    </p>
                    <hr className='mt-3 col-span-2'/>
                    <p className='col-span-3 mt-2'>
                        FISA course (Engineer Training under Apprentice Status) work-study at Thales<br/>
                        Cybersecurity major<br/>
                    </p>
                </div>

                <div className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3'>
                    <h5 className='flex text-lg font-bold col-span-2 items-center'>
                        Centria UAS
                    </h5>
                    <a className='flex justify-end row-span-2 items-center ml-6' href="https://net.centria.fi/en/" target='blank_'>
                        <img className='max-w-full max-h-14 rounded' src={logo_centria} alt=''/>
                    </a>
                    <p className='col-span-2'>
                        <b>Summer school</b>
                    </p>
                    <p className='mt-1 text-sm text-left italic'>
                        International exchange to validate the 6th semester at ESIEA 
                    </p>
                    <p className='col-span-2 m-1 text-sm text-right italic'>
                        May 2023 - July 2023
                    </p>
                    <hr className='mt-3 col-span-2'/>
                    <p className='col-span-3 mt-2'>
                        7 week period in Finland<br/>
                        Courses taught in English<br/>
                        Soft skills of the engineer<br/>
                        Web development with Django & React.JS<br/>
                    </p>
                </div>
                
                <div className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3'>
                    <h5 className='flex text-lg font-bold col-span-2 items-center'>
                        IUT Reims-Châlons-Charleville
                    </h5>
                    <a className='flex justify-end row-span-2 items-center ml-6' href="https://www.univ-reims.fr/iut-rcc/" target='blank_' >
                        <img className='max-w-full max-h-14' src={logo_iut} alt=''/>
                    </a>
                    <p className='col-span-2'>
                        <b>DUT in Computer Science</b>
                    </p>
                    <p className='mt-1 text-sm text-left italic'>
                        Bac+2 (120 ECTS Credits)
                    </p>
                    <p className='col-span-2 m-1 text-sm text-right italic'>
                        Sept. 2020 - Aug. 2022
                    </p>
                    <hr className='mt-3 col-span-2'/>
                    <p className='col-span-3 mt-2'>
                        Acquisition of professional skills in programming and application development
                    </p>
                </div>
            </div>
        );
    } else if (hash === "#socials") {
        return (
            <div className='glass p-3 mt-3 col-span-4 grid grid-cols-1 grid-flow-row gap-4'>
                <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3 hover:border-white' href="https://www.linkedin.com/in/mkhaddan/" target="_blank" rel="noreferrer">
                    <h5 className='flex text-lg font-bold col-span-2 items-center'>
                        Linkedin
                    </h5>
                    <div className='flex justify-end row-span-2 items-center'>
                        <img className='max-h-8 text-right mt-1 mb-1' src={logo_linkedin} alt=''/>
                    </div>
                    <div className='col-span-2'>
                        @mkhaddan
                    </div>
                </a>
                <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3 hover:border-white' href="https://github.com/MOSEMBIK" target="_blank" rel="noreferrer">
                    <h5 className='flex text-lg font-bold col-span-2 items-center'>
                        GitHub
                    </h5>
                    <div className='flex justify-end row-span-2 items-center'>
                        <img className='max-h-8 text-right mt-1 mb-1' src={logo_github} alt=''/>
                    </div>
                    <div className='col-span-2'>
                        @MOSEMBIK
                    </div>
                </a>
                <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3 hover:border-white' href="mailto://mohammad.khaddan@gmail.com" target="_blank" rel="noreferrer">
                    <h5 className='flex text-lg font-bold col-span-2 items-center'>
                        Mail
                    </h5>
                    <div className='flex justify-end row-span-2 items-center'>
                        <img className='max-h-8 text-right mt-1 mb-1' src={logo_mail} alt=''/>
                    </div>
                    <div className='col-span-2'>
                        mohammad.khaddan@gmail.com
                    </div>
                </a>
            </div>
        );
    } else {
        return (
            <div className='glass p-3 mt-3 col-span-4 grid grid-cols-1 grid-flow-row gap-4'>
                <div className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3'>
                    <h5 className='flex items-center text-lg font-bold col-span-2'>Mohammad KHADDAN</h5>
                    <div className='flex row-span-3 justify-end items-center'>
                        <img className='max-w-full max-h-20' src={me_head} alt=''/>
                    </div>
                    <b className='flex items-center text-base font-bold col-span-2'>DevOps, DevNetOps & IT Lover</b>
                    <hr className='mt-3 col-span-2'/>
                    <p className='col-span-3 mt-2'>
                        In love with technology, innovation and problem solving.<br/>
                        Having always loved learning, I'm constantly motivated to improve my skills and acquire new knowledge.<br/>
                    </p>
                    <hr className='mt-3 col-span-3 border-dashed'/>
                    <p className='col-span-3 mt-2'>
                        Specialized in Python and scripting languages. Quick to assimilate new contexts. I am able to bring automation and optimization in your workflows.<br/>
                        With experience in APIs and microservices, I am able to help you design and deploy modern and scalable applications OnPremise or Cloud based.<br/>
                    </p>
                    <hr className='mt-3 col-span-3 border-dashed'/>
                    <p className='col-span-3 mt-2'>
                        You can find my socials here if you wish to contact me.<br/>
                        {'-> '} 
                        <a className="italic underline hover:no-underline hover:font-medium" href="#socials">
                            My socials.
                        </a>
                    </p>
                </div>
            </div>
        );
    };
}

export default function Whoami() {
    const [searchParams] = useSearchParams();
    const lang = (searchParams.get('lang') ? searchParams.get('lang').toLowerCase() : '');

    const location = useLocation();
    const hash = location.hash;

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )

    useEffect(() => {
        document.title = "whoami? | Mohammad KHADDAN";
        window
            .matchMedia("(min-width: 768px)")
            .addEventListener('change', e => setMatches( e.matches ));
    }, []);
    
    return (
        <div className="page Whoami">
            <Header />
            <div id="PageBody" className="container mx-auto p-2 pt-14 pb-20 text-white">
                <h1 className="hidden">WHOAMI</h1>
                <h2 className='text-center mt-2'>
                    {(() => {
                        switch (lang) {
                            case "en":     return 'A bit more about me :)';
                            case "fr":     return 'Un peu plus sur moi :)';
                            default:       return 'Un peu plus sur moi :)';
                        }
                    })()}
                </h2>
                <div className={(matches ? ('w-2/3') : ('p-2')) + ' m-auto mt-4 Cards grid grid-cols-4 gap-2 text-center'}>
                    <a className={((hash === '') ? 'selected-card' : '') + ' Card glass p-1'} href='# '>
                        {(
                            matches ? 
                            ( () => {
                                switch (lang) {
                                    case "en":     return '🧑‍🦱 Me';
                                    case "fr":     return '🧑‍🦱 Moi';
                                    default:       return '🧑‍🦱 Moi';
                                }
                            })() : ('🧑‍🦱')
                        )}
                    </a>
                    <a className={((hash === '#career') ? 'selected-card' : '') + ' Card glass p-1'} href='#career'>
                        {(
                            matches ? 
                            ( () => {
                                switch (lang) {
                                    case "en":     return '💼 Career';
                                    case "fr":     return '💼 Carrière';
                                    default:       return '💼 Carrière';
                                }
                            })() : ('💼')
                        )}
                    </a>
                    <a className={((hash === '#education') ? 'selected-card' : '') + ' Card glass p-1'} href='#education'>
                        {(
                            matches ? 
                            ( () => {
                                switch (lang) {
                                    case "en":     return '🎓 Education';
                                    case "fr":     return '🎓 Scolarité';
                                    default:       return '🎓 Scolarité';
                                }
                            })() : ('🎓')
                        )}
                    </a>
                    <a className={((hash === '#socials') ? 'selected-card' : '') + ' Card glass p-1'} href='#socials'>
                        {(
                            matches ? 
                            ( () => {
                                switch (lang) {
                                    case "en":     return '📱 Socials';
                                    case "fr":     return '📱 Réseaux';
                                    default:       return '📱 Réseaux';
                                }
                            })() : ('📱')
                        )}
                    </a>
                    {(() => {
                        switch (lang) {
                            case "en":  return <CONTENT_EN/>;
                            case "fr":  return <CONTENT_FR/>;
                            default:    return <CONTENT_FR/>;
                        }
                    })()}
                </div>
            </div>
            <Tags/>
            <Footer/>
        </div> 
    );
};

