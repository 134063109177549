import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import './css/Header.css';
import arrow_pointing_down from './svg/arrow_pointing_down.svg';
import english from './svg/uk.svg';
import francais from './svg/france.svg';












const getHref = (lang) => {
  switch (lang) {
    case 'en': return {'home':'/?lang=en', 'portfolio':'/portfolio?lang=en', 'whoami':'/whoami?lang=en'};
    case 'fr': return {'home':'/?lang=fr', 'portfolio':'/portfolio?lang=fr', 'whoami':'/whoami?lang=fr'};
    default:  return {'home':'/', 'portfolio':'/portfolio', 'whoami':'/whoami'};
  }
}

export default function Header() {
  const location = useLocation();
  const pathname = location.pathname;

  const [searchParams] = useSearchParams();
  const lang = (searchParams.get('lang') ? searchParams.get('lang').toLowerCase() : '');

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 550px)").matches
  )

  useEffect(() => {
    window
    .matchMedia("(min-width: 550px)")
    .addEventListener('change', e => setMatches( e.matches ));
  }, []);

  const [showNav2, setShowNav2] = useState(false);
  const sayHello = () => {
    setShowNav2(!showNav2);
  };

  if (matches) {
    return (
      <div className='Header z-50 pb-1 w-full flex flex-col items-center'>
        <div className="container mx-auto flex items-end">
          <nav className="grid grid-cols-10 grow text-center text-white">
            <div className="col-start-1 col-span-5 flex justify-start">
              <a href={getHref(lang).home} className={((pathname === '/') ? "navButtonActive" : "") +" navButton size-min text-left text-2xl ml-2"}>
                mohammadkdn
              </a>
            </div>
            <div className="col-span-2 flex justify-end">
              <a href={getHref(lang).portfolio} className={((pathname === '/portfolio') ? "navButtonActive" : "") +" navButton size-min text-left text-2xl"}>
              portfolio
              </a>
            </div>
            <div className="col-span-2 flex justify-center">
              <a href={getHref(lang).whoami} className={((pathname === '/whoami') ? "navButtonActive" : "") +" navButton size-min text-left text-2xl"}>
                whoami
              </a>
            </div>
            <div className='col-end-11 flex justify-center items-center'>
              <a href={lang === 'en' ? '?lang=fr' : '?lang=en'} className="lang-selector mt-1 navButton size-min">
                {(() => {
                    switch (lang) {
                        case "en":  return <img className='min-w-4' src={francais} alt=''/>;
                        case "fr":  return <img className='min-w-4' src={english} alt=''/>;
                        default:    return <img className='min-w-4' src={english} alt=''/>;
                    }
                })()}
              </a>
            </div>
          </nav>
        </div>
      </div>
    );
  } else {
    return (
      <div className='Header z-50 pb-1 w-full flex flex-col items-center'>
        <div className="container mx-auto flex flex-col items-center">
          <nav className="grid grid-col-6 w-full items-center text-center text-white">
            <div className="col-start-1 col-end-4 flex justify-start">
              <a href={getHref(lang).home} className={((pathname === '/') ? "navButtonActive" : "") +" navButton text-left text-2xl ml-2"}>
                mohammadkdn
              </a>
            </div>
            <div className={showNav2 ? ("navActionRotate col-end-5 flex justify-center") : ("navAction col-end-5 flex justify-center")}>
              <img src={arrow_pointing_down} className="w-5" onClick={sayHello} alt=''/>
            </div>
          </nav>
          <nav className={(showNav2 ? ("") : ("hidden")) + ' grid grid-cols-7 w-full text-center text-white'}>
            <div className="col-span-3 flex justify-center">
              <a href={getHref(lang).portfolio} className={((pathname === '/portfolio') ? "navButtonActive" : "") +" navButton text-left text-2xl"}>
                portfolio
              </a>
            </div>
            <div className="col-span-3 flex justify-center">
              <a href={getHref(lang).whoami} className={((pathname === '/whoami') ? "navButtonActive" : "") +" navButton text-left text-2xl"}>
                whoami
              </a>
            </div>
            <div className='ml-1 flex justify-start items-center'>
              <a href={lang === 'en' ? '?lang=fr' : '?lang=en'} className="lang-selector mt-1 navButton size-min">
                {(() => {
                    switch (lang) {
                        case "en":  return <img className='min-w-4' src={francais} alt=''/>;
                        case "fr":  return <img className='min-w-4' src={english} alt=''/>;
                        default:    return <img className='min-w-4' src={english} alt=''/>;
                    }
                })()}
              </a>
            </div>
          </nav>
        </div>
      </div>
    );
  }
};
