import Header from '../components/Header';
import Footer from '../components/Footer';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import './css/Portfolio.css';

import logo_melusine from '../components/img/melusine.png';
import logo_gambas from '../components/svg/gambas.svg';
import logo_ficlimbr from '../components/img/FiClimbr.png';
import logo_ygdrsl from '../components/svg/ygdrsl.svg';
import me_png from '../components/img/me.png';
import logo_liftlinker from '../components/svg/liftlinker.svg';
import logo_kprsr from '../components/svg/kprsr.svg';





const Tags = () => {
    return (
        <div id='tags' className='hidden'>
            <p>
                Portfolio ; Projets ; Projects ; Réalisations ; Realizations ; Expériences ; Experiences ; Compétences ; Skills ; Technologies ; Technologies utilisées ; Technologies used ; Langages de programmation ; Programming languages ; Frameworks ; Bibliothèques ; Libraries ; Outils ; Tools ; Logiciels ; Software ; Systèmes d'exploitation ; Operating systems ; OS ; mohammadkdn ; mohammad.kdn ; mohammad-kdn ; mohammad_kdn ; mohammad-khaddan ; mohammad_kh
            </p>
        </div>
    )
}

// ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤
// Components bodies
const GambasBody = () => {
    const [searchParams] = useSearchParams();
    const lang = (searchParams.get('lang') ? searchParams.get('lang').toLowerCase() : '');

    const cardFR = (
        <div className='p-3 grid grid-cols-1 grid-flow-row gap-4'>
            Gambas <br/>
            FRANCAIS
        </div>
    );
    const cardEN = (
        <div className='p-3 grid grid-cols-1 grid-flow-row gap-4'>
            Gambas <br/>
            ANGLAIS
        </div>
    );

    return (() => {
        switch (lang) {
            case "en":  return cardEN;
            case "fr":  return cardFR;
            default:    return cardFR;
        }
    })();
};

const KPRSRBody = () => {
    const [searchParams] = useSearchParams();
    const lang = (searchParams.get('lang') ? searchParams.get('lang').toLowerCase() : '');

    const cardFR = (
        <div className='p-3 grid grid-cols-1 grid-flow-row gap-4'>
            Media KPRSR <br/>
            FRANCAIS
        </div>
    );
    const cardEN = (
        <div className='p-3 grid grid-cols-1 grid-flow-row gap-4'>
            Media KPRSR <br/>
            ANGLAIS
        </div>
    );

    return (() => {
        switch (lang) {
            case "en":  return cardEN;
            case "fr":  return cardFR;
            default:    return cardFR;
        }
    })();
};

const LiftLinkerBody = () => {
    const [searchParams] = useSearchParams();
    const lang = (searchParams.get('lang') ? searchParams.get('lang').toLowerCase() : '');

    const cardFR = (
        <div className='p-3 grid grid-cols-1 grid-flow-row gap-4'>
            LiftLinker <br/>
            FRANCAIS
        </div>
    );
    const cardEN = (
        <div className='p-3 grid grid-cols-1 grid-flow-row gap-4'>
            LiftLinker <br/>
            ANGLAIS
        </div>
    );

    return (() => {
        switch (lang) {
            case "en":  return cardEN;
            case "fr":  return cardFR;
            default:    return cardFR;
        }
    })();
};

const YgdrslBody = () => {
    const [searchParams] = useSearchParams();
    const lang = (searchParams.get('lang') ? searchParams.get('lang').toLowerCase() : '');

    const cardFR = (
        <div className='p-3 grid grid-cols-1 grid-flow-row gap-4'>
            YGDRSL <br/>
            FRANCAIS
        </div>
    );
    const cardEN = (
        <div className='p-3 grid grid-cols-1 grid-flow-row gap-4'>
            YGDRSL <br/>
            ANGLAIS
        </div>
    );

    return (() => {
        switch (lang) {
            case "en":  return cardEN;
            case "fr":  return cardFR;
            default:    return cardFR;
        }
    })();
};

const MelusineBody = () => {
    const [searchParams] = useSearchParams();
    const lang = (searchParams.get('lang') ? searchParams.get('lang').toLowerCase() : '');

    const cardFR = (
        <div className='p-3 grid grid-cols-1 grid-flow-row gap-4'>
            Melusine <br/>
            FRANCAIS <br/>
            https://github.com/MAIF/melusine <br/>
            https://camembert-model.fr/
        </div>
    );
    const cardEN = (
        <div className='p-3 grid grid-cols-1 grid-flow-row gap-4'>
            Melusine <br/>
            ANGLAIS <br/>
            https://github.com/MAIF/melusine <br/>
            https://camembert-model.fr/
        </div>
    );

    return (() => {
        switch (lang) {
            case "en":  return cardEN;
            case "fr":  return cardFR;
            default:    return cardFR;
        }
    })();
};

const FiclimbrBody = () => {
    const [searchParams] = useSearchParams();
    const lang = (searchParams.get('lang') ? searchParams.get('lang').toLowerCase() : '');

    const cardFR = (
        <div className='p-3 grid grid-cols-1 grid-flow-row gap-4'>
            FiClimbr <br/>
            FRANCAIS
        </div>
    );
    const cardEN = (
        <div className='p-3 grid grid-cols-1 grid-flow-row gap-4'>
            FiClimbr <br/>
            ANGLAIS
        </div>
    );

    return (() => {
        switch (lang) {
            case "en":  return cardEN;
            case "fr":  return cardFR;
            default:    return cardFR;
        }
    })();
};




// ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤
// Components cards
const GambasCard = () => {
    const [searchParams] = useSearchParams();
    const lang = (searchParams.get('lang') ? searchParams.get('lang').toLowerCase() : '');

    
    // <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3 hover:border-white' href="#gambas">
    const cardFR = (
        <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3'>
            <h5 className='flex text-lg font-bold col-span-2 items-center'>
            GAMBAS
            </h5>
            <div className='flex justify-end row-span-3 items-center'>
                <img className='max-w-full max-h-20 p-2' src={logo_gambas} alt=''/>
            </div>
            <div className='col-span-2 mt-1 text-sm italic'>
                Portail d'administration pour équipements réseau
            </div>
            <hr className='mt-3 col-span-2'/>
            <div className='col-span-3'>
                GAMBAS est un projet de portail d'administration pour équipements réseau ayant pour objectif de faciliter le travail de collaborateurs administrateurs et d'automatiser certaines tâches d'analyse et de configuration.
            </div>
        </a>
    );
    // <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3 hover:border-white' href="#gambas">
    const cardEN = (
        <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3'>
            <h5 className='flex text-lg font-bold col-span-2 items-center'>
            GAMBAS
            </h5>
            <div className='flex justify-end row-span-3 items-center'>
                <img className='max-w-full max-h-20 p-2' src={logo_gambas} alt=''/>
            </div>
            <div className='col-span-2 mt-1 text-sm italic'>
                Portail d'administration pour équipements réseau
            </div>
            <hr className='mt-3 col-span-2'/>
            <div className='col-span-3'>
                GAMBAS is an administration web portal project aimed at facilitating the work of administrative collaborators and automating certain analysis and configuration tasks on network equipments.
            </div>
        </a>
    );

    return (() => {
        switch (lang) {
            case "en":  return cardEN;
            case "fr":  return cardFR;
            default:    return cardFR;
        }
    })();
};

const YgdrslCard = () => {
    const [searchParams] = useSearchParams();
    const lang = (searchParams.get('lang') ? searchParams.get('lang').toLowerCase() : '');

    // <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3 hover:border-white' href="#ygdrsl">
    const cardFR = (
        <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3'>
            <h5 className='flex text-lg font-bold col-span-2 items-center'>
                YGGDRASIL
            </h5>
            <div className='flex justify-end row-span-3 items-center'>
                <img className='max-w-full max-h-20 p-2' src={logo_ygdrsl} alt=''/>
            </div>
            <div className='col-span-2 mt-1 text-sm italic'>
                Infrastructure IT / HomeLab
            </div>
            <hr className='mt-3 col-span-2'/>
            <div className='col-span-3'>
                YGGDRASIL est mon projet d'infrastructure IT personnelle (HomeLab) ayant pour objectif de me permettre de tester de nouvelles technologies et développer mes compétences en matière d'architecture, administration et gestion.
            </div>
        </a>
    );
    // <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3 hover:border-white' href="#ygdrsl">
    const cardEN = (
        <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3'>
            <h5 className='flex text-lg font-bold col-span-2 items-center'>
                YGGDRASIL
            </h5>
            <div className='flex justify-end row-span-3 items-center'>
                <img className='max-w-full max-h-20 p-2' src={logo_ygdrsl} alt=''/>
            </div>
            <div className='col-span-2 mt-1 text-sm italic'>
                IT Infrastructure / HomeLab
            </div>
            <hr className='mt-3 col-span-2'/>
            <div className='col-span-3'>
                YGGDRASIL is my personal IT infrastructure project (HomeLab) aimed at allowing me to test new technologies and develop my skills in architecture, administration and management.
            </div>
        </a>
    );

    return (() => {
        switch (lang) {
            case "en":  return cardEN;
            case "fr":  return cardFR;
            default:    return cardFR;
        }
    })();
};

const WebsiteCard = () => {
    const [searchParams] = useSearchParams();
    const lang = (searchParams.get('lang') ? searchParams.get('lang').toLowerCase() : '');

    // <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3 hover:border-white' href="#website">
    const cardFR = (
        <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3'>
            <h5 className='flex text-lg font-bold col-span-2 items-center'>
                mohammadkdn.fr
            </h5>
            <div className='flex justify-end row-span-3 items-center'>
                <img className='max-w-full max-h-20 p-2' src={me_png} alt=''/>
            </div>
            <div className='col-span-2 mt-1 text-sm italic'>
                Site web / Portfolio
            </div>
            <hr className='mt-3 col-span-2'/>
            <div className='col-span-3'>
                Mon site web personnel. <br/>
                Il s'agit d'un portfolio de mes réalisations, de mes compétences et de mes expériences (il est également possible de télécharger mon CV).
            </div>
        </a>
    );
    // <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3 hover:border-white' href="#website">
    const cardEN = (
        <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3'>
            <h5 className='flex text-lg font-bold col-span-2 items-center'>
                mohammadkdn.fr
            </h5>
            <div className='flex justify-end row-span-3 items-center'>
                <img className='max-w-full max-h-20 p-2' src={me_png} alt=''/>
            </div>
            <div className='col-span-2 mt-1 text-sm italic'>
                Website / Portfolio
            </div>
            <hr className='mt-3 col-span-2'/>
            <div className='col-span-3'>
                My personnal website. <br/>
                It's a portfolio of my realizations, my skills and my experiences (you can also download my CV).
            </div>
        </a>
    );

    return (() => {
        switch (lang) {
            case "en":  return cardEN;
            case "fr":  return cardFR;
            default:    return cardFR;
        }
    })();
};

const KPRSRCard = () => {
    const [searchParams] = useSearchParams();
    const lang = (searchParams.get('lang') ? searchParams.get('lang').toLowerCase() : '');

    // <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3 hover:border-white' href="#kprsr">
    const cardFR = (
        <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3'>
            <h5 className='flex text-lg font-bold col-span-2 items-center'>
                Media KPRSR
            </h5>
            <div className='flex justify-end row-span-3 items-center'>
                <img className='max-w-full max-h-20 p-2' src={logo_kprsr} alt=''/>
            </div>
            <div className='col-span-2 mt-1 text-sm italic'>
                Programmation GPU (CUDA)
            </div>
            <hr className='mt-3 col-span-2'/>
            <div className='col-span-3'>
                Accélération GPU pour la compression d’image.<br/>
                lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
            </div>
        </a>
    );
    // <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3 hover:border-white' href="#kprsr">
    const cardEN = (
        <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3'>
            <h5 className='flex text-lg font-bold col-span-2 items-center'>
                Media KPRSR
            </h5>
            <div className='flex justify-end row-span-3 items-center'>
                <img className='max-w-full max-h-20 p-2' src={logo_kprsr} alt=''/>
            </div>
            <div className='col-span-2 mt-1 text-sm italic'>
                GPU Programming (CUDA)
            </div>
            <hr className='mt-3 col-span-2'/>
            <div className='col-span-3'>
                GPU acceleration for image compression. <br/>
                It's a portfolio of my realizations, my skills and my experiences (you can also download my CV).
            </div>
        </a>
    );

    return (() => {
        switch (lang) {
            case "en":  return cardEN;
            case "fr":  return cardFR;
            default:    return cardFR;
        }
    })();
};

const LiftLinkerCard = () => {
    const [searchParams] = useSearchParams();
    const lang = (searchParams.get('lang') ? searchParams.get('lang').toLowerCase() : '');

    // <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3 hover:border-white' href="#liftlinker">
    const cardFR = (
        <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3'>
            <h5 className='flex text-lg font-bold col-span-2 items-center'>
                LiftLinker
            </h5>
            <div className='flex justify-end row-span-3 items-center'>
                <img className='max-w-full max-h-20 p-2' src={logo_liftlinker} alt=''/>
            </div>
            <div className='col-span-2 mt-1 text-sm italic'>
                Site web / Réseau social
            </div>
            <hr className='mt-3 col-span-2'/>
            <div className='col-span-3'>
                Forum sur le musculation et le fitness. <br/>
                Il s'agissait d'un site ou les utilisateurs pouvaient s'authentifier et obtenir des informations et des conseils sur leurs pratiques sportives favorites ainsi que poser des questions et partager des conversations autour de posts du forum.
            </div>
        </a>
    );
    // <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3 hover:border-white' href="#liftlinker">
    const cardEN = (
        <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3'>
            <h5 className='flex text-lg font-bold col-span-2 items-center'>
                LiftLinker
            </h5>
            <div className='flex justify-end row-span-3 items-center'>
                <img className='max-w-full max-h-20 p-2' src={logo_liftlinker} alt=''/>
            </div>
            <div className='col-span-2 mt-1 text-sm italic'>
                Website / Social network
            </div>
            <hr className='mt-3 col-span-2'/>
            <div className='col-span-3'>
                Forum on bodybuilding and fitness. <br/>
                It was a website project where users could authenticate and get information and advice on their favorite sports practices as well as ask questions and share conversations around forum posts.
            </div>
        </a>
    );

    return (() => {
        switch (lang) {
            case "en":  return cardEN;
            case "fr":  return cardFR;
            default:    return cardFR;
        }
    })();
};

const MelusineCard = () => {
    const [searchParams] = useSearchParams();
    const lang = (searchParams.get('lang') ? searchParams.get('lang').toLowerCase() : '');

    // <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3 hover:border-white' href="#melusine">
    const cardFR = (
        <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3'>
            <h5 className='flex text-lg font-bold col-span-2 items-center'>
                Melusine | CamemBert
            </h5>
            <div className='flex justify-end row-span-3 items-center'>
                <img className='max-w-full max-h-20 p-2' src={logo_melusine} alt=''/>
            </div>
            <div className='col-span-2 mt-1 text-sm italic'>
                Implémentation de filtrage d'emails par IA
            </div>
            <hr className='mt-3 col-span-2'/>
            <div className='col-span-3'>
                Projet de mise en place du filtrage des mails reçus par un modèle d'IA NLM basé sur CamemBert avec le développement d'API customisées et de traitements spécifique dérivés du projet Melusine de la MAIF.
            </div>
        </a>
    );
    // <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3 hover:border-white' href="#melusine">
    const cardEN = (
        <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3'>
            <h5 className='flex text-lg font-bold col-span-2 items-center'>
                Melusine | CamemBert
            </h5>
            <div className='flex justify-end row-span-3 items-center'>
                <img className='max-w-full max-h-20 p-2' src={logo_melusine} alt=''/>
            </div>
            <div className='col-span-2 mt-1 text-sm italic'>
                Implementing AI mail filtering
            </div>
            <hr className='mt-3 col-span-2'/>
            <div className='col-span-3'>
                Project to set up filtering of received emails by an NLM AI model based on CamemBert with the development of custom APIs and specific processing derived from the MAIF's Melusine project.
            </div>
        </a>
    );

    return (() => {
        switch (lang) {
            case "en":  return cardEN;
            case "fr":  return cardFR;
            default:    return cardFR;
        }
    })();
};

const FiclimbrCard = () => {
    const [searchParams] = useSearchParams();
    const lang = (searchParams.get('lang') ? searchParams.get('lang').toLowerCase() : '');

    // <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3 hover:border-white' href="#ficlimbr">
    const cardFR = (
        <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3'>
            <h5 className='flex text-lg font-bold col-span-2 items-center'>
                FiClimbr
            </h5>
            <div className='flex justify-end row-span-3 items-center'>
                <img className='max-w-full max-h-20 p-2' src={logo_ficlimbr} alt=''/>
            </div>
            <div className='col-span-2 mt-1 text-sm italic'>
                Bot de trading de cryptomonnaies & de news financières
            </div>
            <hr className='mt-3 col-span-2'/>
            <div className='col-span-3'>
                FiClimbr est un projet de bot de trading de cryptomonnaies et de news financières ayant pour objectif de permettre à ses utilisateur de suivre les actualités financières et de laisser le programme prendre des décisions de trading automatisées sur un portefeuille dédié.
            </div>
        </a>
    );
    // <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3 hover:border-white' href="#ficlimbr">
    const cardEN = (
        <a className='glass text-left pt-1 pb-1 pl-3 pr-3 grid grid-cols-3'>
            <h5 className='flex text-lg font-bold col-span-2 items-center'>
                FiClimbr
            </h5>
            <div className='flex justify-end row-span-3 items-center'>
                <img className='max-w-full max-h-20 p-2' src={logo_ficlimbr} alt=''/>
            </div>
            <div className='col-span-2 mt-1 text-sm italic'>
                Bot de trading de cryptomonnaies & de news financières
            </div>
            <hr className='mt-3 col-span-2'/>
            <div className='col-span-3'>
                FiClimbr is a project of a bot for trading cryptocurrencies and retriving informations aimed at allowing its users to follow financial news and let the program make automated trading decisions on a dedicated wallet.
            </div>
        </a>
    );

    return (() => {
        switch (lang) {
            case "en":  return cardEN;
            case "fr":  return cardFR;
            default:    return cardFR;
        }
    })();
};




// ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤
// Main component
export default function Portfolio() {
    const [searchParams] = useSearchParams();
    const lang = (searchParams.get('lang') ? searchParams.get('lang').toLowerCase() : '');

    const location = useLocation();
    const hash = location.hash;

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )

    useEffect(() => {
        document.title = "Portfolio | Mohammad KHADDAN";
        window
            .matchMedia("(min-width: 768px)")
            .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    return (
        <div>
            <Header />
            <div className="page Portfolio">
                <div id="PageBody" className="container mx-auto p-2 pt-14 pb-20 text-white">
                    <h1 className="hidden">Portfolio</h1>
                    <h2 className='text-center mt-2'>
                        {(() => {
                            switch (lang) {
                                case "en":     return 'Here is bit of my work.';
                                case "fr":     return 'Voici quelques unes de mes réalisations.';
                                default:       return 'Voici quelques unes de mes réalisations.';
                            }
                        })()}
                    </h2>
                    
                    <div className={(matches ? ('w-2/3') : ('p-2')) + ' m-auto mt-4 Cards grid grid-cols-4 gap-2 text-center'}>
                        <a className={((hash === '') ? 'selected-card' : '') + ' Card glass p-1'} href='# '>
                            {(
                                matches ? 
                                ( () => {
                                    switch (lang) {
                                        case "en":     return '🗂️ All';
                                        case "fr":     return '🗂️ Tout';
                                        default:       return '🗂️ Tout';
                                    }
                                })() : ('🗂️')
                            )}
                        </a>
                        <a className={((hash === '#pro') ? 'selected-card' : '') + ' Card glass p-1'} href='#pro'>
                            {(
                                matches ? 
                                ( () => {
                                    switch (lang) {
                                        case "en":     return '💼 Pro';
                                        case "fr":     return '💼 Pro';
                                        default:       return '💼 Pro';
                                    }
                                })() : ('💼')
                            )}
                        </a>
                        <a className={((hash === '#academic') ? 'selected-card' : '') + ' Card glass p-1'} href='#academic'>
                            {(
                                matches ? 
                                ( () => {
                                    switch (lang) {
                                        case "en":     return '🎓 Academic';
                                        case "fr":     return '🎓 Scolaire';
                                        default:       return '🎓 Scolaire';
                                    }
                                })() : ('🎓')
                            )}
                        </a>
                        <a className={((hash === '#perso') ? 'selected-card' : '') + ' Card glass p-1'} href='#perso'>
                            {(
                                matches ? 
                                ( () => {
                                    switch (lang) {
                                        case "en":     return '👨‍💻 Personal';
                                        case "fr":     return '👨‍💻 Personel';
                                        default:       return '👨‍💻 Personel';
                                    }
                                })() : ('👨‍💻')
                            )}
                        </a>
                        
                        <div className='glass p-3 mt-3 col-span-4 grid grid-cols-1 grid-flow-row gap-4'>
                            {(() => {
                                switch (hash) {
                                    // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤
                                    // Show Section
                                    /*
                                    case "#gambas":     return <GambasBody />;
                                    case "#ygdrsl":     return <YgdrslBody />;
                                    case "#kprsr":      return <KPRSRBody />;
                                    case "#liftlinker": return <LiftLinkerBody />;
                                    case "#melusine":   return <MelusineBody />;
                                    case "#ficlimbr":   return <FiclimbrBody />;
                                    */
                                    // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤
                                    // Show filtered
                                    case "#pro":
                                        return (
                                            <div className='col-span-4 grid grid-cols-1 grid-flow-row gap-4'>
                                                <GambasCard />
                                                <MelusineCard />
                                            </div>
                                        );
                                    case "#academic":
                                        return (
                                            <div className='col-span-4 grid grid-cols-1 grid-flow-row gap-4'>
                                                <KPRSRCard />
                                                <LiftLinkerCard />
                                            </div>
                                        );
                                    case "#perso":
                                        return (
                                            <div className='col-span-4 grid grid-cols-1 grid-flow-row gap-4'>
                                                <YgdrslCard />
                                                <WebsiteCard />
                                                <FiclimbrCard />
                                            </div>
                                        );
                                    default:
                                        return (
                                            <div className='col-span-4 grid grid-cols-1 grid-flow-row gap-4'>
                                                <GambasCard />
                                                <YgdrslCard />
                                                <WebsiteCard />
                                                <KPRSRCard />
                                                <LiftLinkerCard />
                                                <MelusineCard />
                                                <FiclimbrCard />
                                            </div>
                                        );
                                }
                            })()}
                        </div>
                    </div>
                </div>
            </div>
            <Tags />
            <Footer/>
        </div>
    );
};