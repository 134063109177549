import Header from '../components/Header';
import Footer from '../components/Footer';
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import './css/Home.css';
import CV_FR_pdf from '../public/CV_FR.pdf';
import CV_EN_pdf from '../public/CV_EN.pdf';
import portfolio_img from '../components/img/portfolio.png';
import education_img from '../components/img/education.png';
import career_img from '../components/img/career.png';
import socials_img from '../components/img/socials.png';
import cv_fr_img from '../components/img/cv_fr_img.png';
import cv_en_img from '../components/img/cv_en_img.png';





const Tags = () => {
    return (
        <div id='tags' className='hidden'>
            <p>
                Home page ; Mohammad KHADDAN ; IT engineer ; mohammadkdn ; mohammad.kdn ; mohammad-kdn ; mohammad_kdn ; mohammad-khaddan ; mohammad_kh ; Page d'acceuil ; Page principale ; Page de garde ; Page de démarrage ; Page de lancement ; Page de départ ; Page de bienvenue ; Page d'entrée ; Page d'arrivée
            </p>
        </div>
    )
}

const HOME_FR = () => {
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 550px)").matches
    )

    useEffect(() => {
        document.title = "Accueil | Mohammad KHADDAN";
        window
            .matchMedia("(min-width: 550px)")
            .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    return (
        <div className={(matches ? ('w-2/3') : ('p-2')) + ' m-auto mt-4 Cards grid grid-cols-2 grid-row-flows gap-2'}>
            <a className='Card glass row-span-2' href='/portfolio'>
                <div className='flex flex-col h-full items-center justify-center justify-evenly'>
                    <h4 className='text-center text-2xl m-2'>projets</h4>
                    <img src={portfolio_img} alt='portfolio illustration' className='max-h-80 row-span-4 mx-auto' />
                </div>
            </a>
            <a className='Card glass col-end-3' href='/whoami#career'>
                <div className='flex flex-col h-full items-center justify-center justify-evenly'>
                    <h4 className='text-center text-2xl m-2'>carrière</h4>
                    <img src={career_img} alt='career illustration' className='max-h-40 row-span-3 mx-auto' />
                </div>
            </a>
            <a className='Card glass col-end-3' href='/whoami#education'>
                <div className='flex flex-col h-full items-center justify-center justify-evenly'>
                    <h4 className='text-center text-2xl m-2'>scolarité</h4>
                    <img src={education_img} alt='education illustration' className='max-h-40 row-span-3 mx-auto' />
                </div>
            </a>
            <div className='glass grid grid-rows-4 gap-2'>
                <h4 className='text-center text-2xl'>CVs</h4>
                <div className='row-span-3 grid grid-cols-2 gap-1'>
                    <a className='glass my-2 ml-1 hover:border-white flex items-center justify-center' href={CV_FR_pdf} target = "_blank" rel="noreferrer">
                        <img src={cv_fr_img} alt='cv français' className='max-h-20 col-span-6 m-1' />
                    </a>
                    <a className='glass my-2 mr-1 hover:border-white flex items-center justify-center' href={CV_EN_pdf} target = "_blank" rel="noreferrer">
                        <img src={cv_en_img} alt='cv english' className='max-h-20 col-span-6 m-1' />
                    </a>
                </div>
            </div>
            <a className='Card glass' href='/whoami#socials'>
                <div className='flex flex-wrap h-full items-center justify-center'>
                    <h4 className='text-center text-2xl mx-4'>coordonées</h4>
                    <img src={socials_img} alt='socials icons' className='max-h-20 col-span-6 my-4' />
                </div>
            </a>
        </div>
    );
}

const HOME_EN = () => {
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 550px)").matches
    )

    useEffect(() => {
        document.title = "Home | Mohammad KHADDAN";
        window
            .matchMedia("(min-width: 550px)")
            .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    return (
        <div className={(matches ? ('w-2/3') : ('p-2')) + ' m-auto mt-4 Cards grid grid-cols-2 grid-row-flows gap-2'}>
            <a className='Card glass row-span-2' href='/portfolio'>
                <div className='flex flex-col h-full items-center justify-center justify-evenly'>
                    <h4 className='text-center text-2xl m-2'>projects</h4>
                    <img src={portfolio_img} alt='portfolio illustration' className='max-h-80 row-span-4 mx-auto' />
                </div>
            </a>
            <a className='Card glass col-end-3' href='/whoami#career'>
                <div className='flex flex-col h-full items-center justify-center justify-evenly'>
                    <h4 className='text-center text-2xl m-2'>career</h4>
                    <img src={career_img} alt='career illustration' className='max-h-40 row-span-3 mx-auto' />
                </div>
            </a>
            <a className='Card glass col-end-3' href='/whoami#education'>
                <div className='flex flex-col h-full items-center justify-center justify-evenly'>
                    <h4 className='text-center text-2xl m-2'>education</h4>
                    <img src={education_img} alt='education illustration' className='max-h-40 row-span-3 mx-auto' />
                </div>
            </a>
            <div className='glass grid grid-rows-4 gap-2'>
                <h4 className='text-center text-2xl'>CVs</h4>
                <div className='row-span-3 grid grid-cols-2 gap-1'>
                    <a className='glass my-2 ml-1 hover:border-white flex items-center justify-center' href={CV_FR_pdf} target = "_blank" rel="noreferrer">
                        <img src={cv_fr_img} alt='cv français' className='max-h-20 col-span-6 m-1' />
                    </a>
                    <a className='glass my-2 mr-1 hover:border-white flex items-center justify-center' href={CV_EN_pdf} target = "_blank" rel="noreferrer">
                        <img src={cv_en_img} alt='cv english' className='max-h-20 col-span-6 m-1' />
                    </a>
                </div>
            </div>
            <a className='Card glass' href='/whoami#socials'>
                <div className='flex flex-wrap h-full items-center justify-center'>
                    <h4 className='text-center text-2xl mx-4'>contact details</h4>
                    <img src={socials_img} alt='socials icons' className='max-h-20 col-span-6 my-4' />
                </div>
            </a>
        </div>
    );
}

export default function Home() {
    const [searchParams] = useSearchParams();
    const lang = (searchParams.get('lang') ? searchParams.get('lang').toLowerCase() : '');

    return (
        <div>
            <Header />
            <div className="page Home">
                <div id="PageBody" className="container mx-auto p-2 pt-14 pb-20 text-white">
                    <h1 className="hidden">Home page</h1>
                    <h2 className='text-center mt-2'>
                        {(() => {
                            switch (lang) {
                                case "en":     return ("I'm Mohammad KHADDAN :)",<br/>,"Welcome on my website.");
                                case "fr":     return ("Je suis Mohammad KHADDAN :)",<br/>,"Bienvenu sur mon site.");
                                default:       return ("Je suis Mohammad KHADDAN :)",<br/>,"Bienvenu sur mon site.");
                            }
                        })()}
                    </h2>
                    {(() => {
                        switch (lang) {
                            case "en":  return <HOME_EN />;
                            case "fr":  return <HOME_FR />;
                            default:    return <HOME_FR />;
                        }
                    })()}
                </div>
            </div> 
            <Tags />
            <Footer/>
        </div>
    );
};
  